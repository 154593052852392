import { createContainer } from 'unstated-next';
import { 
  AsyncValue,
} from './types';

import {
  toAsyncValue,
  useAsyncState,
  useFetch,
} from './hooks';

import {
  Item
} from '../types';
import { itemApi } from '../api';

export type ItemStoreResult = {

  readonly itemState: AsyncValue<Item | undefined>

  readonly itemFetchByTag: AsyncValue<Item | undefined>

  fetchItemById(id: number): Promise<void>;

  fetchItemByTag(tag: string): Promise<void>;
}

function useItemStore(): ItemStoreResult {

  const itemState = useAsyncState<Item>();

  const itemFetchByTag = useAsyncState<Item>();

  const fetchItemById = useFetch(itemState, itemApi.fetchItemById);

  const fetchItemByTag = useFetch(itemFetchByTag, itemApi.fetchItemByTag);

  return {
    itemState: toAsyncValue(itemState),
    itemFetchByTag: toAsyncValue(itemFetchByTag),
    fetchItemById,
    fetchItemByTag
  };
}

export const ItemStore = createContainer(useItemStore); 
