import axios from 'axios';

import { 
  Item
} from '../types';

export type ItemApi = {
    fetchItemById(id: number): Promise<Item>;
    fetchItemByTag(tag: string): Promise<Item>;
}

export const itemApi: ItemApi = {

  async fetchItemById(id: number): Promise<Item> {
    const response = await axios.get(`/api/items/${id}`)
      .then((res) => res);
    return response.data;
  },
  async fetchItemByTag(tag: string): Promise<Item> {
    const response = await axios.get('/api/item', { params : { tagValue: tag }})
      .then((res) => res);
    return response.data;
  }
};
