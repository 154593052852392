import { FunctionComponent } from "react";
import type { Container } from "unstated-next";

import { ItemStore, AvailabilityStore, UserStore } from ".";

// All stores that depend on other stores must come after their dependencies
const storeContainers: Container<any>[] = [ItemStore, AvailabilityStore, UserStore].reverse();

type Props = {
    children?: React.ReactNode;
};

export const StoreContainer: FunctionComponent <Props> = ({ children }) => {
  return storeContainers.reduce<JSX.Element>(
    (acc, state) => <state.Provider>{acc}</state.Provider>,
    <>{children}</>
  );
};
