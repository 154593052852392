import { createContainer } from 'unstated-next';

import { userApi } from '../api';
import { UsernameDTO } from '../types';

import {
  toAsyncValue,
  useAsyncState,
  useFetch,
} from './hooks';
import { AsyncValue } from './types';

export type UserStoreResult = {
  readonly fetchUsernameState: AsyncValue<UsernameDTO | undefined>;
  fetchUsernameById(userId: string): Promise<void>;
}

function useUserStore(): UserStoreResult {
  const fetchUsernameState = useAsyncState<UsernameDTO>();
  const fetchUsernameById = useFetch(fetchUsernameState, userApi.fetchUsernameById);

  return {
    fetchUsernameState: toAsyncValue(fetchUsernameState),
    fetchUsernameById,
  };
}

export const UserStore = createContainer(useUserStore);
