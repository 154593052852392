import { FunctionComponent, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ItemStore, AvailabilityStore, UserStore } from '../../stores';
import { State } from '../../stores/types';
import { Availability, Item, UsernameDTO } from '../../types';
import './DetailsPage.css';
import { AiFillPhone, AiOutlineMail } from 'react-icons/ai';
import { GrMapLocation } from 'react-icons/gr';
import contactTypes from '../../utils/contact-types';

const DetailsPage: FunctionComponent = () => {
  const id = Number(useParams().itemId);
  const [itemId] = useState<number>(id);
  const [error, setError] = useState<string>('');
  const [item, setItem] = useState<Item>();


  const { state } = useLocation();

  const [availabilities, setAvailabilities] = useState<Availability[] | undefined>(undefined);
  const [user, setUser] = useState<UsernameDTO | undefined>(undefined);

  const {
    itemState, 
    fetchItemById,
  } = ItemStore.useContainer();

  const {
    fetchUsernameState,
    fetchUsernameById,
  } = UserStore.useContainer();

  const {
    fetchAvailabilitiesState,
    fetchAvailabilities,
  } = AvailabilityStore.useContainer();

  useEffect(() => {
    if (state == null) {
      fetchItemById(itemId);
    } else {
      const paramItem = state.item;
      setItem(paramItem);
      if (paramItem.userId != null) {
        fetchAvailabilities(paramItem.userId);
        fetchUsernameById(paramItem.userId);
      }
    }
  }, []);

  useEffect(() => {
    if (fetchUsernameState.state == State.DONE) {
      setUser(fetchUsernameState.value);
    } else if(fetchUsernameState.state == State.ERROR) {
      console.error("Failed to fetch username");
      setError(fetchUsernameState.lastError);
    }
  }, [fetchUsernameState, fetchUsernameState.state]);

  useEffect(() => {
    if (fetchAvailabilitiesState.state == State.DONE) {
      setAvailabilities(fetchAvailabilitiesState.value);
    } else if(fetchAvailabilitiesState.state == State.ERROR) {
      console.error("Failed to fetch availabilities");
      setError(fetchAvailabilitiesState.lastError);
    }
  }, [fetchAvailabilitiesState, fetchAvailabilitiesState.state]);

  useEffect(() => {
    if (itemState.state === State.DONE) {
      setItem(itemState.value);
      itemState.reset();
      setError('');
      if (itemState.value?.userId != null) {
        fetchAvailabilities(itemState.value?.userId);
        fetchUsernameById(itemState.value?.userId);
      }
    } else if (itemState.state === State.ERROR) {
      setError(itemState.lastError);
    }
  }, [itemState, itemState.state]);

  return (
    <div>
      {
        error.length > 0 && 
        <p> {error} </p> 
      }
      <h1 className='username-header'> Owner: {user?.username} </h1>
      <div className='is-lost'>
        <h2 className='availabilities-header'> {item?.name} </h2> 
      </div>
      <div>
        <p className='item-description'> {item?.description} </p>
      </div>
      <h2 className='availabilities-header'> Availabilities </h2>
      <div className='item-description'>
        {
          availabilities != undefined && <>
            {
              availabilities.map((availability, i) => {
                return (
                  <div className='availability-container' key={i}>
                    { 
                      availability.discriminator == contactTypes.phoneNumber &&
                      <p className='availability'><AiFillPhone/>{availability.contact}</p>
                    }
                    { 
                      availability.discriminator == contactTypes.address &&
                      <p className='availability'> <GrMapLocation/> {availability.contact}</p>
                    }
                    { 
                      availability.discriminator == contactTypes.email &&
                      <p className='availability'> <AiOutlineMail/> {availability.contact}</p>
                    }
                  </div>
                );
              }) 
            }
          </>  
        }
      </div>
    </div>
  );
};

export default DetailsPage;
