import { FunctionComponent} from "react";
import QrReader from 'react-web-qr-reader';
import { useNavigate} from "react-router-dom";
import './QRCodeScannerPage.css';

const QRCodeScannerPage: FunctionComponent = () => {
  const delay = 500;

  const navigate = useNavigate();

  const handleScan = (result:any) => {
    if (result) {
      const link = new URL(result.data);
      const queryParams = new URLSearchParams(link.search);
      const objectId = queryParams.get("id");
      navigate(`/items/${objectId}`);
    }
  };

  const handleError = (error:any) => {
    console.error(error);
  };

  if (window.innerWidth < 760) 
    return (
      <div className="main-container">
        <QrReader
          delay={delay}
          style={{height: 300,width: 350}}
          onError={handleError}
          onScan={handleScan}
        />
      </div>
    );
  else 
    return (
      <div className="main-container">
        <QrReader
          delay={delay}
          style={{height: 500,width: 500}}
          onError={handleError}
          onScan={handleScan}
        />
      </div>
    );
};

export default QRCodeScannerPage;
