import { createContainer } from 'unstated-next';

import { availabilityApi } from '../api';
import { Availability } from '../types';

import { AsyncValue } from './types';

import {
  toAsyncValue,
  useAsyncState,
  useFetch,
} from './hooks';

export type AvailabilityStoreResult = {

  readonly fetchAvailabilitiesState: AsyncValue<Availability[] | undefined>;

  fetchAvailabilities(userId: string): Promise<void>;
}

function useAvailabilityStore(): AvailabilityStoreResult {
  const fetchAvailabilitiesState = useAsyncState<Availability[]>();

  const fetchAvailabilities = useFetch(fetchAvailabilitiesState, availabilityApi.fetchContacts);

  return {
    fetchAvailabilitiesState: toAsyncValue(fetchAvailabilitiesState),
    fetchAvailabilities,
  };
}

export const AvailabilityStore = createContainer(useAvailabilityStore);
