export enum State {
    INITIAL,
    PENDING,
    DONE,
    ERROR,
    PARTIAL
}
  
export type AsyncValue<T> = {
    value: T;
    state: State;
    lastError?: any;
    reset(): void;
}
  
export type RequestResult = {
    state: State;
    lastError?: any;
    reset(): void;
}
  