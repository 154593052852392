import { FunctionComponent } from 'react';
import './Button.css';

interface DeepLinkButtonProps {
  title: string;
  url: string;
}

const DeepLinkButton: FunctionComponent<DeepLinkButtonProps> = ({url, title}) => {
  return (
    <button type="button" className="button-style" onClick={() => window.location.assign(url)}>
      {title}
    </button>
  );
};

export default DeepLinkButton;
