import ResolverPage from './pages/ResolverPage/ResolverPage';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { StoreContainer } from './stores/store-container';
import DetailsPage from './pages/DetailsPage/DetailsPage';
import { FunctionComponent } from 'react';
import SearchPage from './pages/SearchPage/SearchPage';
import QRCodeScannerPage from './pages/QRCodeScannerPage/QRCodeScannerPage';

const App: FunctionComponent = () => {
  
  axios.defaults.baseURL = 'https://myowner.test.edu.codespring.ro';   
  axios.defaults.headers['ngrok-skip-browser-warning'] = 'any';

  return (
    <StoreContainer>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ResolverPage/>}/>
          <Route path="/items/:itemId" element={<DetailsPage/>}/>
          <Route path="/findmyitem" element={<SearchPage/>}/>
          <Route path="/scanner" element={<QRCodeScannerPage/>}/>
        </Routes>
      </BrowserRouter>
    </StoreContainer>
  );
};

export default App;
