import { FunctionComponent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";
import { ItemStore } from '../../stores';
import { State } from '../../stores/types';
import './SearchPage.css';

const SearchPage: FunctionComponent = () => {
  library.add(faSearch);
  const [inputText, setInputText] = useState<string>('');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const {
    itemFetchByTag,
    fetchItemByTag,
  } = ItemStore.useContainer();

  useEffect(() => {
    if (itemFetchByTag.state === State.DONE) {
      const item = itemFetchByTag.value;
      if (item !== undefined) {
        navigate(`/items/${item.itemId}`, {state: {item}});
      }
      itemFetchByTag.reset();
    } else if (itemFetchByTag.state === State.ERROR) {
      setError("No item was found");
    }
  }, [itemFetchByTag, itemFetchByTag.state]);

  const handleClick = () => {
    if(inputText.length > 0) {
      fetchItemByTag(inputText);
    }
  };

  return (
    <div className="main-container">
      <div className="container">
        <input 
          type="text" 
          className="search-bar" 
          placeholder="Search..."
          onChange={event => setInputText(event.target.value)}
        />
        <button 
          type="button" 
          className="search-button"
          onClick={handleClick}
        >
          <FontAwesomeIcon icon="search"/>
        </button>
      </div>
      {
        error.length > 0 && <p className="no-tag"> {error} </p> 
      }
    </div>
  );
};

export default SearchPage;
