import axios from 'axios';
import { UsernameDTO } from '../types';

export type UserApi = {
    fetchUsernameById(userId: string): Promise<UsernameDTO>
}

export const userApi: UserApi = {

  async fetchUsernameById(userId: string): Promise<UsernameDTO> {
    const response = await axios.get(`/api/users/${userId}/username`)
      .then((res) => res);
    return response.data;
  },
};
