import { FunctionComponent } from 'react';
import ResolverButton from '../../components/ResolverButton';
import './ResolverPage.css';
import { useSearchParams } from 'react-router-dom';
import DeepLinkButton from '../../components/DeepLinkButton';


const ResolverPage: FunctionComponent = () => {

  const [searchParams] = useSearchParams();
  const objectId = searchParams.get("id");
  const appURL: any = searchParams.get("appUrl");
  const continueURL = `/items/${objectId}`;

  return (
    <div className="main-container">
      <div className="welcome-container">
        <div className="welcome-message">
          <h2>Hello MyOwner-web app!</h2>
        </div>
        <div className="buttons-container">
          <ResolverButton title="Download application" path="/download"/>
          <ResolverButton title="Continue in browser" path={continueURL}/>
          <DeepLinkButton title="Open in application" url={appURL}/>
          <ResolverButton title="QR Code Scanner" path="/scanner"/>
          <ResolverButton title="Find item by text tag" path="/findmyitem"/>
        </div>
      </div>
    </div>
  );
};

export default ResolverPage;
