import { FunctionComponent } from 'react';
import './Button.css';


interface ResolverButtonProps {
  title: string;
  path: string;
}

const ResolverButton: FunctionComponent<ResolverButtonProps> = ({path, title}) => {
  return (
    <a href={path}>
      <button type="button" className="button-style">
        {title}
      </button>
    </a>
  );
};

export default ResolverButton;
