import React, { useCallback, useMemo, useRef, useState } from "react";

import { AsyncValue, RequestResult, State } from "./types";

export interface AsyncState<T> {
  // Value of the variable
  value: T;

  // Request state
  state: State;

  // Stores error, cleared on succes
  lastError?: any;

  // Sets the state PENDING
  pending(): void;

  // Updates the variable and sets state to DONE
  done(newValue: React.SetStateAction<T>): void;

  // Sets state to ERROR
  error(err: any): void;

  // Sets state to INITIAL
  reset(): void;
}

export function useAsyncState<T>(
  initialState: T | (() => T)
): Readonly<AsyncState<T>>;

export function useAsyncState<T = undefined>(): Readonly<
	AsyncState<T | undefined>
>;

export function useAsyncState<T>(
  initialState?: T | (() => T)
): Readonly<AsyncState<T>> {
  const [value, setValue] = useState<T>(initialState!);
  const [state, setState] = useState(State.INITIAL);
  const lastError = useRef<any>();

  const returnedObject: Partial<AsyncState<T>> = useMemo(
    () => ({
      pending: () => {
        setState(State.PENDING);
      },
      done: (newValue: React.SetStateAction<T>) => {
        lastError.current = undefined;
        setValue(newValue);
        setState(State.DONE);
      },
      error: (error: any) => {
        lastError.current = error;
        setState(State.ERROR);
      },
      reset: () => {
        lastError.current = undefined;
        setState(State.INITIAL);
      },
    }),
    []
  );

  returnedObject.state = state;
  returnedObject.value = value;
  returnedObject.lastError = lastError.current;

  return returnedObject as AsyncState<T>;
}

export const toAsyncValue = <T>(
  asyncState: Readonly<AsyncState<T>>
): AsyncValue<T> => ({
    value: asyncState.value,
    state: asyncState.state,
    lastError: asyncState.lastError,
    reset: asyncState.reset,
  });

export function useFetch<T, Params extends any[]>(
  asyncState: AsyncState<T> | RequestState,
  fetchFunction: (...args: Params) => Promise<T>
): (...args: Params) => Promise<void> {
  return useCallback(
    async (...args: any) => {
      try {
        asyncState.pending();
        const response = await fetchFunction(...args);
        asyncState.done(response);
      } catch (error: any) {
        asyncState.error(error.response.data);
      }
    },
    [fetchFunction, asyncState]
  );
}

export interface RequestState {
	state: State;
  
	lastError?: any;

	pending(): void;

	done(): void;

	error(err: any): void;

	reset(): void;
}

export function useRequestState(): Readonly<RequestState> {
  const [state, setState] = useState(State.INITIAL);
  const lastError = useRef<any>();

  const returnedObject: Partial<RequestState> = useMemo(
    () => ({
      pending: () => {
        setState(State.PENDING);
      },
      done: () => {
        lastError.current = undefined;
        setState(State.DONE);
      },
      error: (error: any) => {
        lastError.current = error;
        setState(State.ERROR);
      },
      reset: () => {
        lastError.current = undefined;
        setState(State.INITIAL);
      },
    }),
    []
  );

  returnedObject.state = state;
  returnedObject.lastError = lastError.current;

  return returnedObject as RequestState;
}

export const toRequestResult = (
  requestState: Readonly<RequestState>
): RequestResult => ({
  state: requestState.state,
  lastError: requestState.lastError,
  reset: requestState.reset,
});
