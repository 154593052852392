import axios from 'axios';
import { Availability } from '../types';

export type AvailabilityApi = {
  fetchContacts(userId: string): Promise<Availability[]>
}

export const availabilityApi: AvailabilityApi = {
  async fetchContacts(userId: string): Promise<Availability[]> {
    const response = await axios.get(`/api/contacts/${userId}`)
      .then((res) => res);
    return response.data;
  }
};  
